import axios from "axios"
import { Brand, brand, infinityConfig } from "../config";
import { json2xml, xml2Json } from "../utils/xml";
import { toUserRegisterRequestXml } from "./infinity-rest-api/user-register";
import { Dealer } from '../types/system';
import { UserRegister } from "../types/user-register";
import { toSystemDealers } from "./infinity-rest-api/system-dealer";
import { configureAxiosRetryForNonceError } from '../utils/configureAxiosRetryForNonceError';

const toXWwwFormEncodedPayload = (obj: any): string => {
  const xmlStr = json2xml(obj);
  return "data=" + encodeURIComponent(xmlStr);
}

export interface InfinityUserAuthenticationInfoXML {
  credentials: {
    username: string;
    password: string;
    params: {
      brand: Brand;
    };
  };
}

export enum IsSystemInfoRegistrationResponse {
  SUCCESS,
  INVALID_SERIAL_NUMBER_OR_HAS_NOT_CONNECT,
  INVALID_MAC_PIN,
  ALREADY_REGISTERED
}

const axiosInstance = axios.create({
  baseURL: infinityConfig.baseURL
});

configureAxiosRetryForNonceError(axiosInstance);

export const isSystemInfoRegistrationValid = async (serialNumber: string, macAddress: string, pin: string): Promise<IsSystemInfoRegistrationResponse> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    },
    params: {
      macAddress: macAddress,
      pin: pin,
      username: null
    }
  }

  const username = localStorage.getItem("username");
  if (username) {
    options.params.username = username;
  }

  try {
    await axiosInstance.get(`/systems/${serialNumber}/verify`, options);
    return IsSystemInfoRegistrationResponse.SUCCESS;
  } catch (e) {
    const status = e.response?.status;
    if (status === 404) {
      return IsSystemInfoRegistrationResponse.INVALID_SERIAL_NUMBER_OR_HAS_NOT_CONNECT;
    } else if (status === 400) {
      return IsSystemInfoRegistrationResponse.INVALID_MAC_PIN;
    } else if (status === 409) {
      return IsSystemInfoRegistrationResponse.ALREADY_REGISTERED;
    }
    throw e;
  }
}

export const isUsernameRegistrationValid = async (username: string): Promise<boolean> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    }
  }

  try {
    await axiosInstance.get(`/users/verify/username/${username}`, options);
    return true;
  } catch (e) {
    if (e.response?.status === 409) {
      return false;
    }
    console.error(e);
    throw e;
  }
}

export const isEmailRegistrationValid = async (email: string): Promise<boolean> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    }
  }

  try {
    await axiosInstance.get(`/users/verify/email/${email}`, options);
    return true;
  } catch (e) {
    if (e.response?.status === 409) {
      return false;
    }
    console.error(e);
    throw e;
  }
}

export const registerNewUser = async (userRegister: UserRegister): Promise<void> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    }
  }

  const userRegisterRequest = toUserRegisterRequestXml(userRegister);
  const data = toXWwwFormEncodedPayload(userRegisterRequest);

  try {
    await axiosInstance.post(`/users/register`, data, options);
  } catch (e) {
    if (e.response?.data) {
      const responseObj = await xml2Json(e.response?.data);

      const errorMessage = responseObj?.error?.message;
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }

    throw e;
  }
}

export const recoverUserPassword = async (userEmail: string): Promise<void> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    }
  }

  const requestBody = {
    passwordRecover: {
      usernameOrEmail: userEmail,
      brand: brand.value
    }
  };
  const data = toXWwwFormEncodedPayload(requestBody);

  try {
    await axiosInstance.post(`/users/recover/password`, data, options);
  } catch (e) {
    if (e.response?.data) {
      const responseObj = await xml2Json(e.response?.data);

      const errorMessage = responseObj?.error?.message;
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }

    throw e;
  }
}

export const recoverUsername = async (userEmail: string): Promise<void> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    }
  }

  const requestBody = {
    userRecover: {
      email: userEmail,
      brand: brand.value,
    }
  };

  const data = toXWwwFormEncodedPayload(requestBody);

  try {
    await axiosInstance.post(`/users/recover/username`, data, options);
  } catch (e) {
    if (e.response?.data) {
      const responseObj = await xml2Json(e.response?.data);

      const errorMessage = responseObj?.error?.message;
      if (errorMessage) {
        throw new Error(errorMessage);
      }
    }

    throw e;
  }
}

export const resetUserPassword = async (passwordReset: any): Promise<Boolean> => {
  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    }
  }

  const requestBody = {
    passwordReset: {
      token: passwordReset.resetToken,
      newPassword: passwordReset.password
    }
  };
  const data = toXWwwFormEncodedPayload(requestBody);

  return await axiosInstance.post(`/users/reset/password`, data, options).then(function (response) {
    return true;
  })
    .catch(function () {
      return false;
    });
}

export const getDealers = async (postalCode: string, distance: number, brands: string): Promise<Dealer[]> => {
  const axiosInstance = axios.create({
    baseURL: infinityConfig.baseURL
  });

  configureAxiosRetryForNonceError(axiosInstance);

  const options = {
    auth: {
      username: infinityConfig.consumerKey,
      password: infinityConfig.consumerSecret
    },
    params: {
      postalCode: postalCode,
      distance: distance,
      brands: brands
    }
  };

  const response = await axiosInstance.get(`/dealers`, options);
  const responseObj = await xml2Json(response.data);

  const systemDealers = toSystemDealers(responseObj);
  return systemDealers;
}
